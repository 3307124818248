<template>
  <div>
    <GenericForm
      id="form"
      title="Material Sem QR Code"
      previousRoute="materialSemQRCode"
      editRoute="materialSemQRCodeEditar"
      :model="model"
      :route="materialGenericoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_material_generico"
      :permissionsToWrite="['rw_vinculo_externo']"
      :permissionsToEdit="['re_vinculo_externo']"
      :permissionsToDelete="['rd_vinculo_externo']"
      refreshPageAfterSave
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col cols="6">
            <div>
              <Button
                @click="openModalFoto"
                v-if="hasFotos"
                variant="grayscale"
                :text="fotosButtonText"
                icon="camera"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <Button
                @click="openModalPrinter"
                icon="printer"
                text="Imprimir QrCode"
              />
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Tipo de material*" label-for="tipoMaterialGenerico">
              <GenericSelect
                name="tipoMaterialGenerico"
                labelKey="nome"
                :value="idTipoMaterialGenerico"
                v-model="model.id_tipo_material_generico"
                route="tipoMaterialGenerico"
                :disabled="isBusy"
                v-validate="{ required: true}"
                :state="validateState('tipoMaterialGenerico')"
                @input="(v) => updateTiposProcessamento(v)"
              ></GenericSelect>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição*">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Peças*" label-for="pecas">
              <b-form-input
                id="pecas"
                type="number"
                v-model="model.pecas"
                autocomplete="off"
                class="invision-input"
                name="pecas"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('pecas')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Tipo de Embalagem" label-for="tipoEmbalagem">
              <GenericSelect
                name="tipoEmbalagem"
                labelKey="nome"
                v-model="model.id_tipo_embalagem"
                route="tipoEmbalagem"
                :disabled="isBusy"
                :state="validateState('tipoEmbalagem')"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group
              label-class="float-left"
              style="border-style: solid;
                border-color: rgb(215, 211, 211);
                border-width: 1px;
                border-radius: 7px;
                padding: 10px;
              "
              label="Processamentos permitidos para o material"
              label-for="Processamentos permitidos para o material"
            >
              <info-icon
                v-b-tooltip.hover.right="
                  'Não é permitido realizar alterações'
                "
                class="tooltip-icon"
              />
              <br><br>
              <b-row cols="12">
                <b-col cols="4">
                  <b-form-group
                    style="margin-left: 8px;"
                    label="Preparo"
                  />
                </b-col>
                <b-col cols="8">
                  <GenericMultipleSelect
                    name="tipoLavagem"
                    v-validate="{ required: false }"
                    ref="tipoLavagem"
                    labelKey="descricao"
                    :value="model.tipo_processamento.tipo_lavagem"
                    :state="validateState('tipoLavagem')"
                    route="tipoLavagem"
                    :disabled="isBusy"
                    @input="state => changeTiposProcessamentos(
                      'tipo_lavagem',
                      state,
                    )"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    style="margin-left: 8px;"
                    label="Esterilização"
                  />
                </b-col>
                <b-col cols="8">
                  <GenericMultipleSelect
                    name="tipoEsterilizacao"
                    v-validate="{ required: false }"
                    ref="tipoEsterilizacao"
                    labelKey="nome"
                    :value="model.tipo_processamento.tipo_esterilizacao"
                    :state="validateState('tipoEsterilizacao')"
                    route="tipoEsterilizacao"
                    :disabled="isBusy"
                    @input="state => changeTiposProcessamentos(
                      'tipo_esterilizacao',
                      state,
                    )"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        </template>
        <template #buttons="{ isBusy, cancel, exclude, onSubmit, exclusionEnabled }">
        <b-row align-h="between" class="pt-4">
          <b-col>
            <Button
              variant="grayscale"
              @click="cancel"
              :class="editing && exclusionEnabled ? 'rounded left' : ''"
              text="Cancelar"
              :disabled="isBusy"
            />
            <Button
              v-if="editing"
              variant="grayscale"
              type="text"
              class="rounded right"
              @click="exclude"
              :disabled="isBusy"
              text="Excluir"
            />
          </b-col>
          <b-col class="text-right">
            <Button
              :disabled="isBusy"
              text="Salvar"
              @click="customSubmit(onSubmit)"
            />
          </b-col>
        </b-row>
      </template>
    </GenericForm>
    <Modal
      id="modalPhoto"
      ref="modalPhoto"
      nome="modalPhoto"
      :title="editing ? 'Fotos do Material' : 'Editar fotos do material'"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <PhotoForm
        :photos="modalPhotos"
        :buttonConfirmar="true"
        downloadRoute="material/downloadFotos"
        :onlyVisualize="model.onlyVisualizeFotos"
        @newPhoto="setImage"
        @confirmar="closeModalFoto"
        :photosName="photosName"
      />
    </Modal>
    <Modal
      id="modalPrinter"
      ref="modalPrinter"
      nome="modalPrinter"
      size="xl"
      centered
    >
      <Printer
        :resultData="materialToPrint"
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
      >
        <template #printContent class="w">
          <div>
            <qrcode-vue
              :value="model.code"
              level="H"
              renderAs="svg"
              class="qr-code centered"
              size="400"
            ></qrcode-vue>
            <br />
            <br />
            <div class="div-text centered">
              {{ model.descricao }}
            </div>
            <br />
          </div>
        </template>
      </Printer>
    </Modal>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';
import Button from '@/components/Utils/Button';
import Modal from '@/components/Utils/Modal';
import Printer from '@/components/Utils/Printer';
import PhotoForm from '@/components/WebCam/PhotoForm';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';
import genericRequest from '@/services/genericRequest';

export default {
  components: {
    GenericForm,
    GenericSelect,
    Button,
    Modal,
    PhotoForm,
    QrcodeVue,
    Printer,
    GenericMultipleSelect,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    materialToPrint() {
      const result = {
        materiais: [this.model],
        directPrint: true,
      };

      return result;
    },
    fotosButtonText() {
      let text = (this.editing || this.model.onlyVisualizeFotos) ? 'Visualizar fotografias' : 'Inserir fotos';
      if (this.model.fotos.length) text = `${text} (${this.model.fotos.length})`;
      return text;
    },
    hasFotos() {
      return !this.model.cadastro_lote;
    },
    photosName() {
      if (this.model.descricao || this.model.id_material !== -1) {
        return `${this.model.descricao}_ID-${this.model.id_material}`;
      }
      return 'material';
    },
  },

  data() {
    return {
      model: {
        id_material_generico: this.$route.params.id || -1,
        nome: '',
        descricao: '',
        pecas: null,
        code: '',
        id_tipo_material_generico: null,
        fotos: [],
        id_tipo_embalagem: null,
        tipoMaterialGenerico: {},
        tipo_processamento: {
          tipo_lavagem: [],
          tipo_esterilizacao: [],
        },
      },
      modalPhotos: [],
      materialGenericoRoute: 'materialGenerico',
      camposMaterial: {},
      idTipoMaterialGenerico: null,
    };
  },

  watch: {
    async id_tipo_material_generico(val) {
      if (this.editing) {
        await this.updateTiposProcessamento(val);
      }
    },
  },

  methods: {
    campoIsEditable(campo) {
      return this.camposMaterial[campo] && this.camposMaterial[campo].editavel;
    },
    updateModel(model) {
      this.model = model;
      this.idTipoMaterialGenerico = this.model.id_tipo_material_generico;
    },
    setImage(images) {
      this.model.fotos = images;
    },

    // TODO: unificar métodos de abertura e fechamento de modais
    openModalPrinter() {
      this.$refs.modalPrinter.show();
    },

    closeModalPrinter() {
      this.$refs.modalPrinter.show();
    },

    openModalFoto(urls) {
      this.modalPhotos = urls || this.model.fotos;
      this.$refs.modalPhoto.show();
    },
    closeModalFoto() {
      this.modalPhotos = [];
      this.$refs.modalPhoto.hide();
    },
    async validateAll() {
      this.$validator.resume();
      const veeValidation = await this.$validator.validateAll();
      return veeValidation;
    },
    async customSubmit(submitFunc) {
      const validation = await this.validateAll();

      if (!validation) {
        swal({
          icon: 'error',
          title: 'Campos não preenchidos corretamente',
          text: 'Favor verificar todos os campos do cadastro do material antes de salvar',
          buttons: { confirm: 'Continuar' },
        });
        return;
      }
      submitFunc();
    },

    async updateTiposProcessamento(val) {
      if (!val) {
        this.model.tipo_processamento.tipo_esterilizacao = [];
        this.model.tipo_processamento.tipo_lavagem = [];
        return;
      }
      if (!this.editing) {
        const tiposProcessamento = await genericRequest.getWithoutPagination(
          {}, `tipoMaterialGenerico/tipoProcessamento/${val}`,
        );
        this.model.tipo_processamento.tipo_esterilizacao = tiposProcessamento.tipoEsterilizacao.map((v) => ({
          label: v.label,
          value: v.value,
        })) || [];

        this.model.tipo_processamento.tipo_lavagem = tiposProcessamento.tipoLavagem.map((v) => ({
          label: v.label,
          value: v.value,
        })) || [];
      }
    },

    changeTiposProcessamentos(ref, value) {
      this.model.tipo_processamento[ref] = value.map((v) => ({
        value: v.value,
        label: v.label,
      }));
    },
  },
};
</script>

<style>
.button-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.centered {
  text-align: center;
}

.div-text {
  font-size: 30px;
}

@media print {
  body * {
    visibility: hidden;
  }
  .div-text {
    position: absolute;
    left: 10%;
    top: 95%;
    width: 100%;
    font-size: 75px;
    text-align: left !important;
  }
  .qr-code {
    margin-left: 350px;
    margin-top: 170px;
  }
}
</style>
